import Swiper from "../node_modules/swiper/js/swiper";

const slideChange = function (swiperName) {
  swiperName.on("slideChange", function () {
    console.log("slide changed")
  })
}

export const mobilSlider = function (x) {
  var swiper = new Swiper(x, {
    slidesPerView: "auto",
    spaceBetween: 0,
    grabCursor: true,
  })

  // slideChange(swiper)
}