import $ from "../node_modules/jquery";

var formID = "3702";
const lang = $("html").attr("lang");
if (lang == "en-US") {
  formID = "3707";
}

var url = "https://alkanlar.com/wp-json/contact-form-7/v1/contact-forms/" + formID + "/feedback";

$("form.yeni-iletisim-formu").on("submit",function (e) {
  e.preventDefault()
  var form = $(this)
  var formData = form.serializeArray()

  var isim, email, phone, konu, message, sms;

  formData.forEach(element => {
    if (element.name == "isim") {
      isim = element.value
    }
    if (element.name == "email") {
      email = element.value
    }
    if (element.name == "phone") {
      phone = element.value
    }
    if (element.name == "konu") {
      konu = element.value
    }
    if (element.name == "message") {
      message = element.value
    }
    if (element.name == "sms") {
      sms = element.value
    }
  })

  var form = new FormData();
  form.append("isim", isim)
  form.append("email", email)
  form.append("phone", phone)
  form.append("konu", konu)
  form.append("message", message)
  form.append("sms", sms)

  var settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: "POST",
    headers: {},
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
    data: form,
  }

  $.ajax(settings).done(function (response) {
    // console.log(response);
    var res = JSON.parse(response);
    if (res.status == "mail_sent") {
      window.location.reload()
    }
  })
})





// yeni iletişim formu

$("input#onay").on("click", function () {
  var check = $(this).is(":checked")
  const sms = $("#smsHidden")

  if (check) {
    sms.attr("value", 1)
  } else {
    sms.attr("value", 0);
  }
})