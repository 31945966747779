import $ from "../node_modules/jquery";
window.$ = window.jQuery = $;

$(".page-kampanya section.filtre-sabit button.button").click(function () {
  $("html, body").animate(
    {
      scrollTop: 0,
    },
    400
  );
})