// import $ from '../node_modules/jquery';
import Swiper from '../node_modules/swiper/js/swiper';
import { mobilSlider } from "./swiper";
// import './teklif';
import './pages/ik';
import './cookie';
import './modal';

window.$ = window.jQuery = $;

$('.mobil-slider').each(function () {
	var selector = $(this).find('.swiper-container')
	mobilSlider(selector)
})

// TweenMax.ticker.fps(120);

var menu = new TimelineMax();
var mSpeed = 0.35;

menu
	.to('.sidemenu', mSpeed, {
		left: 0,
		force3D: true,
		ease: Expo.easeOut
	})
	.to(
		'.closemenu',
		mSpeed,
		{
			visibility: 'visible',
			opacity: 1,
			force3D: true,
			ease: Expo.easeOut
		},
		'-=' + mSpeed - 0.2
	)
	.to('body', 0.1, {
		overflow: 'hidden'
	})
	.progress(0)
	.pause();

$('.hamburger').on("click",function() {
	menu.play();
});

$('.closemenu').on("click",function() {
	menu.reverse();
});

$(window).on("resize",function() {
	var width = $('body').width();
	//console.log(width);
	if (width > 991) {
		menu.reverse();
	}
});

$(document).on("scroll",function() {
	var bodyScroll = $(window).scrollTop();
	//var header = $('header.site-header .master-header').offset().top;
	//console.log(bayilik, bodyScroll);

	if (200 < bodyScroll && !$('header.site-header').hasClass('fixed')) {
		$('header.site-header').addClass('fixed');
	} else if (bodyScroll < 200 && $('header.site-header').hasClass('fixed')) {
		$('header.site-header').removeClass('fixed');
	}
});

/*
var shop = new TimelineMax({
	repeat: -1,
	repeatDelay: 5
});

shop.to('.shop', 0.2, {
	scale: 1.1,
	yoyo: true,
	repeat: 3,
	force3D: true,
	ease: Power2.easeOut,
	yoyoEase: Power1.easeOut
});

$(".shop")
  .on("mouseenter", function () {
    shop.progress(0).pause();
  })
  .on("mouseleave", function () {
    shop.play();
  });
*/

var lang = $('html').attr('lang');
//console.log(lang);
$('body').addClass(lang);

$('svg .bolge').on("mouseenter",function() {
	$('.bolge-selector').fadeIn(100);
});
$("svg .bolge").stop().on("mousemove", function () {
	// var bolge = $(this).offset().top;
	var bolgeAdi = $(this).attr("data-hover");
	$(".bolge-selector").html(bolgeAdi);

	var x = event.clientX;
	var y = event.clientY;
	//console.log(x, y);
	$(".bolge-selector").animate(
		{
			left: x + 20,
			top: y + 20,
		},
		10
	)
})

var detect = $('section.slider').data('device');
// console.log(detect);
var slideEffect = 'fade';
if (detect != "desktop") {
	slideEffect = "default";
}

var swiper1 = new Swiper(".main-slider", {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 0,
  effect: slideEffect,
  grabCursor: false,
  navigation: {
    nextEl: "section.slider .swiper-button-next",
    prevEl: "section.slider .swiper-button-prev",
  },
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  pagination: {
    el: "section.slider .swiper-pagination",
    type: "fraction",
  },
  loop: true,
});

// Mega Slider Lazy Load
var megaLazy = function () {
	setTimeout(() => {
		const img = $(".main-slider .swiper-slide.swiper-slide-active img");
		const megaSrc = img.attr("megaLazy-src");
		const comp = img.attr("comp-src");
		const next = $(".main-slider .swiper-slide.swiper-slide-next img");
		const nextMegaSrc = next.attr("megaLazy-src");
		const nextComp = next.attr("comp-src");
		const prev = $(".main-slider .swiper-slide.swiper-slide-prev img");
		const prevMegaSrc = prev.attr("megaLazy-src");
		const prevComp = prev.attr("comp-src");
		if (megaSrc !== undefined && comp == "false") {
      img.attr("src", megaSrc);
      img.attr("comp-src", "true");
    }
		if (nextMegaSrc !== undefined && nextComp == "false") {
			next.attr("src", nextMegaSrc);
			next.attr("comp-src", "true");
		}
		if (prevMegaSrc !== undefined && prevComp == "false") {
			prev.attr("src", prevMegaSrc);
			prev.attr("comp-src", "true");
		}
	}, 100);
}

new megaLazy();

swiper1.on("slideChange", function () {
	new megaLazy()
})

setTimeout(() => {
	$("section.slider>.loading").addClass("hide")
}, 1000)

if ($(".mega-thumbs")['length'] > 0) {
	var indicator = () => {
		var indLeft = $(".mega-thumbs .thumb.active").offset().left - $(".mega-thumbs .thumb-base").offset().left -4;
		var indWidth = $(".mega-thumbs .thumb.active").width() + 2 + 8;
	
		$(".mega-thumbs .indicator").css("left", indLeft);
		$(".mega-thumbs .indicator").css("width", indWidth);
	}
	indicator()

	swiper1.on('slideChange', function () {
		const index = swiper1.realIndex + 1;
		var className = ".mega-thumbs .thumb-" + index;
		$(".mega-thumbs .thumb.active").removeClass("active");
		$(className).addClass("active");
		indicator()
	})

	$(window).on("resize",()=>{indicator()})
	
	$(".mega-thumbs .thumb").hover(function() {
		var index = $(this).data('index')
		// console.log(index);
		swiper1.slideTo(index)
	})
}

$("svg").on("mouseleave", function () {
  $(".bolge-selector").fadeOut(100)
})

$('html, body').on("scroll",function() {
	$('.bolge-selector').fadeOut(100)
})

$('svg .bolge').on("click",function() {
	var hash = $(this).attr('data-hash')
	location.hash = hash;

	$('.bolge-tablosu.active').removeClass('active');
	$('.' + hash).addClass('active');

	$('html, body').animate(
		{
			scrollTop: $('.bolge-listesi').offset().top - 80
		},
		400
	);

	var teslimatbolgesi = $('section.teslimat-gunleri ul.bolge-listesi').find("." + hash)
	$('section.teslimat-gunleri ul.bolge-listesi > li').each(function () {
		if ( $(this).hasClass('active') ) {
			$(this).removeClass('active')
		}
	})
	teslimatbolgesi.addClass('active')
})

var hashLink = location.hash;
if (hashLink != '') {
	//console.log(hashLink);
	var hashClass = hashLink.split('#')[1];
	//console.log(hashClass);

	$('.bolge-tablosu.active').each(function() {
		$(this).removeClass('active');
	});
	$('.' + hashClass).addClass('active');
}

$('.widget span.post-date').addClass('badge badge-secondary')

var swiper2 = new Swiper('.bayi-slider', {
	spaceBetween: 30,
	effect: 'fade',
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},
	autoplay: {
		delay: 4854,
		disableOnInteraction: false
	},
	loop: true
})

var swiper3 = new Swiper('.top-slider', {
	spaceBetween: 0,
	effect: 'fade',
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination'
	},
	autoplay: {
		delay: 4854,
		disableOnInteraction: false
	},
	loop: true
})

var swiper4 = new Swiper('.mini-slider-1', {
	spaceBetween: 0,
	effect: 'fade',
	grabCursor: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination'
	},
	autoplay: {
		delay: 5000,
		disableOnInteraction: false
	},
	loop: true
})

var swiper5 = new Swiper('.mini-slider-2', {
	spaceBetween: 30,
	effect: 'fade',
	grabCursor: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination'
	},
	autoplay: {
		delay: 5100,
		disableOnInteraction: false
	},
	loop: true
})

var swiper6 = new Swiper('.mini-slider-3', {
	spaceBetween: 30,
	effect: 'fade',
	grabCursor: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination'
	},
	autoplay: {
		delay: 5200,
		disableOnInteraction: false
	},
	loop: true
})

var swiper7 = new Swiper('.mini-slider-4', {
	spaceBetween: 30,
	effect: 'fade',
	grabCursor: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination'
	},
	autoplay: {
		delay: 5300,
		disableOnInteraction: false
	},
	loop: true
})

var swiper8 = new Swiper(".markalar-swiper", {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 0,
  navigation: {
    nextEl: ".markalar-swiper .swiper-button-next",
    prevEl: ".markalar-swiper .swiper-button-prev",
  },
  // loop: true,
  pagination: {
    el: ".markalar-swiper .swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 3236,
    disableOnInteraction: false,
  },
  grabCursor: true,
})

var iletisimHash = window.location.hash;

if (iletisimHash == '#istek-formu') {
	$('html, body').animate(
		{
			scrollTop: $('section.iletisim-formu').offset().top - 100
		},
		700
	);
}

$('.sidemenu ul.menu').addClass('nav');
$('.sidemenu ul.menu > li').each(function() {
	$(this).addClass('nav-item');
	$(this).find('a').addClass('nav-link');
});
$('.sidemenu li.menu-item-has-children').each(function() {
	$(this).addClass('dropdown');
	$(this).children('a').addClass('dropdown-toggle');
	$(this).children('a').attr('data-toggle', 'dropdown');
});
$('.sidemenu li.menu-item-has-children > ul.sub-menu').addClass('dropdown-menu');
$('.sidemenu li.menu-item-has-children > ul.sub-menu li').addClass('dropdown-item');

$('.goTop').on("click",function() {
	$('html, body').animate(
		{
			scrollTop: 0
		},
		700
	)
})

var swiper10 = new Swiper(".makale-swiper", {
  slidesPerView: "auto",
  spaceBetween: 0,
  centeredSlides: true,
  pagination: {
    el: ".makale-swiper .swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".makale-swiper .swiper-button-next",
    prevEl: ".makale-swiper .swiper-button-prev",
  },
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
})

var swiper13 = new Swiper("section.grup-firmalari .swiper-container", {
  slidesPerView: "auto",
  centeredSlides: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  }
})

$('.onay .ertele').on("click",function() {
	$('.anket').removeClass('active');
	$('body').removeClass('no-scroll');
});

$('.onay .devam').on("click",function() {
	$('.sorular').addClass('active');
	$('.onay').fadeOut(400);
	$('.rica').slideUp(400);
});

$('.sorular .nav-pills .nav-item .nav-link').on("click",function() {
	var dp = $(this).attr('data-prog');
	//console.log(dp);

	$('.progress.ilerleme .progress-bar').css('width', dp + '%')
})

$('.btn.ilerle').on("click",function() {
	var dp = $(this).attr('data-prog');
	//console.log(dp);
	var dt = $(this).attr('data-tab');

	$('.progress.ilerleme .progress-bar').css('width', dp + '%');
	$('.nav-link.active').removeClass('active');
	$('.tab' + dt).addClass('active');
	$('.tab-pane.active').removeClass('fade active show');
	$('.tab-pane' + dt).addClass('active show');
})

$('label input.checkbox').on("click",function() {
	if ($(this).is(':checked')) {
		var attr = $(this).attr('name');
		//console.log(attr);

		if (attr == 'paylas') {
			//console.log('paylaş');
			$('.paylas').addClass('active');
		} else if (attr == 's4') {
			//console.log('çekilişe katıl');
		}
	} else {
		var attr = $(this).attr('name');
		var iptal = attr + ' iptal';
		//console.log(iptal);

		if (iptal == 'paylas iptal') {
			//alert('paylaşma iptal');
			$('.paylas').removeClass('active');
		} else if (iptal == 's4 iptal') {
			alert('çekiliş iptal');
		}
	}
})

$('.anket form').on("submit",function(e) {
	e.preventDefault();
	var formData = $(this).serialize();
	console.log(formData);

	$.post('/panel/anket', formData, function(data) {
		if ((data = true)) {
			console.log(data);
			alert('Anket başarılı bir şekilde gönderildi');
			location.reload();
		} else {
			alert('Anket gönderilemedi');
		}
	})
})

$('.cv form').on("submit",function(e) {
	e.preventDefault();
	var formData = $(this).serialize();
	console.log(formData);

	$.post('/panel/basvuru', formData, function(data) {
		if ((data = true)) {
			console.log(data);
			alert(
				'Başvuru bilgileriniz başarılı bir şekilde gönderildi. Bilgileriniz İnsan Kaynakları ekibimiz tarafından incelenip size geri dönüş yapılacaktır.'
			);
			location.reload();
		} else {
			alert('Başvuru gönderilemedi');
			window.location.reload()
		}
	})
})

$('.teklif-sayfa form').on("submit",function(e) {
	e.preventDefault();

	var formData = $(this).serialize();
	console.log(formData);

	//$('.iletisim-formu form button').prop('disabled', true);

	$.post('/panel/teklif', formData, function(data) {
		if (data) {
			// console.log(data);
			alert('Form başarılı bir şekilde gönderildi');
			// location.reload();
		} else {
			// console.log(data);
			alert('Form gönderilemedi');
			//$('.iletisim-formu form button').prop('disabled', false);
		}
	})
})

$("label.not textarea").on("keyup", function () {
  var notChar = $(this).val().length;
  var maxlength = $("label.not textarea").attr("maxlength");
  var remain = maxlength - notChar;
  $("label.not span.remain").text(remain);

  if (remain <= 50 && !$("label.not span.remain").hasClass("min")) {
    $("label.not span.remain").addClass("min");
  } else if (remain > 50 && $("label.not span.remain").hasClass("min")) {
    $("label.not span.remain").removeClass("min");
  }
})

const kurlar = function () {
	// Döviz kurlarını sırayla döndürür..
	// Sıcak Fırsatlarda çalışmaması gerekiyor !!

	const kur = new TimelineMax({
		repeat: -1,
		repeatDelay: 2,
	})
	
	const kurZamani = 3;
	
	kur
		.to('.kurlar p.dolar', 0.7, {
			marginTop: '-=30px',
			delay: kurZamani,
			ease: Expo.easeOut
		})
		.to('.kurlar p.dolar', 0.7, {
			marginTop: '-=30px',
			delay: kurZamani,
			ease: Expo.easeOut
		})
		.to('.kurlar p.dolar', 0.7, {
			marginTop: 0,
			delay: kurZamani,
			ease: Expo.easeOut
		});
}

const currUrl = location.href;
const sicak = "sicak-firsatlar"
const isSicak = currUrl.indexOf(sicak)

if (isSicak < 0) {
	kurlar()
}

$('.iletisim-formu .checkbox').on("click",function() {
	if ($(this).is(':checked')) {
		$('.iletisim-formu .sms').val(1);
	} else {
		$('.iletisim-formu .sms').val(0);
	}
})

$("form.yeni-iletisim-formu").on("submit", function (e) {
  e.preventDefault();

  var formData = $(this).serialize();
  console.log(formData);

  $(".iletisim-formu form button").prop("disabled", true);

  $.post("/panel/iletisim", formData, function (data) {
    if ((data = true)) {
      console.log(data, "test");
      alert("Form başarılı bir şekilde gönderildi");
      //location.reload();
    } else {
      alert("Form gönderilemedi");
      $(".iletisim-formu form button").prop("disabled", false);
    }
  });
});


$('#soru3 label input.checkbox').on("click",function() {
	var val = $(this);
	if (val.is(':checked')) {
		$('input.hidden').val(1);
	} else {
		$('input.hidden').val(0);
	}
})


$('.bolge-tablosu .kisi .bilgi .sehir').each(function() {
	var sehir = $(this).text().split(',');
	//console.log(sehir);
	$(this).html('');
	for (var i = 0; i < sehir.length; i++) {
		$(this).append('<span class="badge badge-success">' + sehir[i] + '</span>');
	}
})

if ( $('body').hasClass('page-template-page_template-teklif') ) {
	setTimeout(function() {
		$('html, body').animate(
			{
				scrollTop: $('body.page-template-page_template-teklif .entry-title').offset().top
			},
			400
		)
	}, 2000)
}

var swiper11 = new Swiper('.swiper-container.tanitim-swiper', {
	effect: 'flip',
	grabCursor: false,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false
	}
})

/*
var swiper12 = new Swiper("section.fiyat-listeleri .swiper-container", {
  slidesPerView: "auto",
  spaceBetween: 0,
  grabCursor: true,
})
*/

/*
$('.diger-uyruk').on("focus", function() {
	$('#diger.radio-uyruk').attr('checked', true).trigger('click');
})

$('#diger.radio-uyruk').on("focus", function() {
	$('.diger-uyruk').focus()
})
*/

var device;
var bodyWidth = $('body').width();
if ( bodyWidth < 768 ) {
	device = 'mobile'
} else if ( bodyWidth < 992 ) {
	device = 'tablet'
} else {
	device = 'desktop'
}

$('body').addClass(device);

if ( $('body').hasClass('category') || $('body').hasClass('archive') ) {
	var catImg = $('section.page-banner');
	var catImgLargeUrl = catImg.data('large');
	var catImgFullUrl  = catImg.data('full');
	// console.log(catImgLargeUrl, catImgFullUrl);

	if ( $('body').hasClass('mobile') ) {
		catImg.attr('style', catImgLargeUrl)
	} else {
		catImg.attr('style', catImgFullUrl)
	}

	$('article.post').each( function() {
		var e = $(this).find('.gorsel').find('img')
		var thumb = e.data('thumb')
		var large = e.data('large')

		if ( $('body').hasClass('mobile') && large !== null ) {
			e.attr('src', large)
		} else if ($('body').hasClass('desktop') && thumb !== null) {
			e.attr('src', thumb)
		}
	})
}

// Tooltip
$('[data-toggle="tooltip"]').tooltip({ sanitize: true });

// DARK TEMA
var darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
// console.log("darkMode: " + darkMode);


// More Tags
$(".more-tags").on("click", function () {
	$(".side-tags").addClass("active")
	$(this).remove()
})

// Çoklu Fiyat Listesi Daha Fazla Açıklama
$(".coklu-fiyat-listesi-page .daha-fazla").on("click", function () {
	$(".aciklama").addClass("active")
	$(this).remove()
})