// İNSAN KAYNAKLARI
$(".askerlik-buton").on("click", function () {
  const durum = $(this).data("durum")
  const durumId = "#" + durum;
  $(durumId).prop("checked", true)
})

$("form.ik").on("submit", function (e) {
  e.preventDefault()

  const form = $(this)
  const formData = form.serializeArray()

  console.log(formData)

  const url = "https://alkanlar.com/wp-json/contact-form-7/v1/contact-forms/3953/feedback";

  var sendForm = new FormData()

  formData.forEach(element => {    
    sendForm.append(element.name, element.value)
  })

  const settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: "POST",
    headers: {},
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
    data: sendForm,
  }

  $.ajax(settings).done(function (response) {
    console.log("response: " + response);
    var res = JSON.parse(response)
    if (res.status == "mail_sent") {
      console.log(res)
      alert(res.message)
      setTimeout(() => {
        // location.reload()
      }, 500)
    } else {
      alert(res.message)
    }
  })
})

/*
$("form.ik input").each(function () {
  const input = $(this)
  const type  = input.attr("type")
  const name  = input.attr("name")
  const placeholder = ' placeholder "' + input.attr("placeholder") + '"';
  var required = ""
  if ( input.attr("required") == "required" ) {
    required = "*"
  }

  // console.log(type, name, input.attr("placeholder"), required);
  var newInput = "";

  if (type == "text") {
    newInput = "[" + type + required + ' ' + name + placeholder + "] <br>";
  } else if (type == "email") {
    newInput = "[" + type + required + ' ' + name + "] <br>";
  } else if (type == "tel") {
    newInput = "[" + type + required + ' ' + name + placeholder + "] <br>";
  } else if (type == "date") {
    newInput = "[" + type + required + ' ' + name + placeholder + "] <br>";
  } else if (type == "number") {
    newInput = "[" + type + required + ' ' + name + placeholder + "] <br>";
  }

  $("code").append(newInput)
})
*/

