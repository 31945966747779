const cookie = localStorage.cookiePolicy;

if (!cookie) {
  $(".coockiePolicy").removeClass("hide")
}

$(".coockiePolicy button.cookie").on("click", function () {
  localStorage.cookiePolicy = true

  $(".coockiePolicy").addClass("hide")
})