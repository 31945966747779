import $ from "../node_modules/jquery";
window.$ = window.jQuery = $;

// Lazy Load

var lazyLoad = function(img) {
  var esp = img.offset().top; // element scroll pos
  var bodyRect = document.body.getBoundingClientRect();

  var wh = window.innerHeight; // window height
  var scPos = bodyRect.y * -1; // scroll position
  var view = wh + scPos + 100;

  if (esp < view ) {
    // console.log('görüntüde');
    var lazy = img.attr("lazy-src");
    var compSrc = img.attr("comp-src");
    // console.log(lazy, compSrc);

    if (lazy != null && compSrc == "false") {
      img.attr("src", lazy);
      img.attr("comp-src", "true");
    }
  }
}

var kartlar = function () {
  $('img').each(function () {
    var img = $(this)
    lazyLoad(img)
  })
}


// Lazy BG

var lazyBG = function(bg) {
  var esp = bg.offset().top; // element scroll pos
  var bodyRect = document.body.getBoundingClientRect();

  var wh = window.innerHeight; // window height
  var scPos = bodyRect.y * -1; // scroll position
  var view = wh + scPos + 100;

  if (esp < view) {
    // console.log('görüntüde');
    var lazy = bg.attr("lazy-style");
    var compStyle = bg.attr("comp-style");
    // console.log(lazy, compStyle);

    if (lazy != null && compStyle == "false") {
      bg.css("background-image", lazy);
      bg.attr("comp-style", true);
    }
  }
}

var bgKartlar = () => {
  $(".lazy-bg").each(function () {
    var bg = $(this);
    lazyBG(bg)
  })
}



// RUN

export const run = () => {
  kartlar()
  bgKartlar()
}

setTimeout(() => {
  run()
}, 1000)

$(window).scroll(function () {
  run()
})

$(window).resize(function () {
  run()
})